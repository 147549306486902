<template>
  <PageBuilder />
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { useNuxtApp } from '#imports'

const { $lenisMount } = useNuxtApp()

onMounted(() => {
  $lenisMount()
})
</script>
